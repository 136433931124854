import { ComponentType, LazyExoticComponent, createElement, lazy } from "react";
import { createRoot } from "react-dom/client";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";

import ErrorPage from "./pages/Error";

import "./index.css";

const route = (
  path: string,
  component: LazyExoticComponent<ComponentType<unknown>>
) => ({
  path,
  element: createElement(component),
  errorElement: <ErrorPage />,
});

const Home = lazy(() => import("./pages/Home"));
const Test = lazy(() => import("./pages/Test"));

const router = createBrowserRouter([
  route("/", Home),
  route("/test", Test),
  { path: "*", element: <Navigate to="/" /> },
]);

createRoot(document.getElementById("root") as HTMLElement).render(
  <RouterProvider {...{ router }} />
);
